.custom-radio-container {
  margin-left: 35px;
  margin-top: 10px;
  cursor: pointer;
}

.custom-radio::after {
  content: "";
  display: block;
  border: 2px solid #013A70;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  transform: translateX(-35px);
  margin-top: 2px;
  transition: all 0.2s ease-in;
}

.custom-radio:checked::after {
  background-color: #0599FF;
  /* border-color: #c0d349; */
  border: 2px solid #013A70;

}

.custom-radio {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
}
